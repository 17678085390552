import React from 'react';
import { connect } from 'react-redux';

const SvgIcons = () => (
	<svg xmlns="http://www.w3.org/2000/svg" display="none">
		<symbol id="statistics-icon-1">
			<path
				d="M175.2 163.4H69.9c-3.9 0-7-3.1-7-7V84.9c0-3.9 3.1-7 7-7h105.3c3.9 0 7 3.1 7 7v71.5c0 3.9-3.1 7-7 7zM69.9 81.9c-1.7 0-3 1.3-3 3v71.5c0 1.7 1.3 3 3 3h105.3c1.7 0 3-1.3 3-3V84.9c0-1.7-1.3-3-3-3H69.9z"
				fill="#1C1C1B"
			/>
			<path
				d="M188.7 150.8c-1.1 0-2-.9-2-2V75.7c0-1.2-1-2.2-2.2-2.2H77.6c-1.1 0-2-.9-2-2s.9-2 2-2h106.9c3.4 0 6.2 2.8 6.2 6.2v73.1c0 1.1-.9 2-2 2z"
				fill="#1C1C1B"
			/>
			<path
				d="M197.1 142.3c-1.1 0-2-.9-2-2v-73c0-1.2-1-2.2-2.2-2.2H86c-1.1 0-2-.9-2-2s.9-2 2-2h106.9c3.4 0 6.2 2.8 6.2 6.2v73.1c0 1-.9 1.9-2 1.9z"
				fill="#1C1C1B"
			/>
			<path
				d="M180.2 93.1H64.9c-1.1 0-2-.9-2-2s.9-2 2-2h115.3c1.1 0 2 .9 2 2s-.9 2-2 2zM71.7 88.1c-1.5 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6zM78 88.1c-1.5 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.1 2.6-2.6 2.6zM84.3 88.1c-1.5 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.1 2.6-2.6 2.6z"
				fill="#1C1C1B"
			/>
			<path
				d="M79.9 150.4c-.5 0-1-.2-1.4-.6-.8-.8-.8-2 0-2.8l29.8-29.8c.8-.8 2-.8 2.8 0l16.5 16.5 31.9-31.9c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L128.9 138c-.8.8-2 .8-2.8 0l-16.5-16.5-28.4 28.4c-.3.3-.8.5-1.3.5z"
				fill="#FF7A50"
			/>
			<path
				d="M161 112.6c-1.1 0-2-.9-2-2v-5.4h-5.4c-1.1 0-2-.9-2-2s.9-2 2-2h7.4c1.1 0 2 .9 2 2v7.4c0 1.1-.9 2-2 2zM130 46.5c-1.1 0-2-.9-2-2V14.9c0-1.1.9-2 2-2s2 .9 2 2v29.6c0 1.1-.9 2-2 2zM130 211.3c-1.1 0-2-.9-2-2v-29.6c0-1.1.9-2 2-2s2 .9 2 2v29.6c0 1.1-.9 2-2 2zM244.3 114.5h-29.6c-1.1 0-2-.9-2-2s.9-2 2-2h29.6c1.1 0 2 .9 2 2s-.9 2-2 2zM45.2 114.5H15.7c-1.1 0-2-.9-2-2s.9-2 2-2h29.6c1.1 0 2 .9 2 2s-1 2-2.1 2zM64.8 46.5c-.5 0-1-.2-1.4-.6L52.2 34.7c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0L66.2 43c.8.8.8 2 0 2.8-.4.5-.9.7-1.4.7zM203.6 194.7c-.5 0-1-.2-1.4-.6l-12.9-12.9c-.8-.8-.8-2 0-2.8s2-.8 2.8 0l12.9 12.9c.8.8.8 2 0 2.8-.3.4-.8.6-1.4.6zM189.9 46.5c-.5 0-1-.2-1.4-.6-.8-.8-.8-2 0-2.8l11.9-11.9c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-11.9 11.9c-.3.4-.8.6-1.4.6zM53.6 195.1c-.5 0-1-.2-1.4-.6-.8-.8-.8-2 0-2.8l11.2-11.2c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L55 194.5c-.4.4-.9.6-1.4.6z"
				fill="#FF7A50"
			/>
		</symbol>
		<symbol id="statistics-icon-2">
			<path
				d="M175.6 188.1c-1.1 0-2-.9-2-2v-85.3c0-1.1.9-2 2-2s2 .9 2 2v85.3c0 1.1-.9 2-2 2zM175.6 81.8c-1.1 0-2-.9-2-2V38.5c0-1.1.9-2 2-2s2 .9 2 2v41.3c0 1.1-.9 2-2 2z"
				fill="#FF7A50"
			/>
			<path
				d="M206.5 64.7h-30.9c-1.1 0-2-.9-2-2V38.5c0-1.1.9-2 2-2h30.9c1.1 0 2 .9 2 2v24.2c0 1.1-.9 2-2 2zm-28.9-4h26.9V40.5h-26.9v20.2z"
				fill="#FF7A50"
			/>
			<path
				d="M223.9 77.7H193c-1.1 0-2-.9-2-2v-13c0-1.1.9-2 2-2s2 .9 2 2v11.1h25.4l-5.4-9.1c-.4-.6-.4-1.4 0-2.1l5.4-9.1h-13.9c-1.1 0-2-.9-2-2s.9-2 2-2h17.4c.7 0 1.4.4 1.7 1 .4.6.3 1.4 0 2L219 63.6l6.6 11.1c.4.6.4 1.4 0 2-.3.6-.9 1-1.7 1z"
				fill="#FF7A50"
			/>
			<path
				d="M124.5 74.6h-.2c-7.9 0-14.4-6.5-14.4-14.4V58c0-7.9 6.5-14.4 14.4-14.4h.2c7.9 0 14.4 6.5 14.4 14.4v2.2c0 7.9-6.4 14.4-14.4 14.4zm-.2-27c-5.7 0-10.4 4.7-10.4 10.4v2.2c0 5.7 4.7 10.4 10.4 10.4h.2c5.7 0 10.4-4.7 10.4-10.4V58c0-5.7-4.7-10.4-10.4-10.4h-.2zM105.9 188.1c-1.1 0-2-.9-2-2v-80.7c0-1.1.9-2 2-2s2 .9 2 2v80.7c0 1.1-.9 2-2 2zM122.8 188.1c-1.1 0-2-.9-2-2v-46.3c0-1.1.9-2 2-2s2 .9 2 2v46.3c0 1.1-.9 2-2 2z"
				fill="#1C1C1B"
			/>
			<path
				d="M139.8 188.1c-1.1 0-2-.9-2-2V95.7c0-1 .7-1.8 1.7-2l39.8-5.4v-5.1h-60c-13.1 0-23.8 10.7-23.8 23.8v29.2c0 1.1-.9 2-2 2s-2-.9-2-2V107c0-15.3 12.5-27.8 27.8-27.8h62c1.1 0 2 .9 2 2V90c0 1-.7 1.8-1.7 2l-39.8 5.4V186c0 1.2-.9 2.1-2 2.1zM80.2 90.2c-7 0-12.7-5.7-12.7-12.7v-1.7c0-7 5.7-12.7 12.7-12.7s12.7 5.7 12.7 12.7v1.7c0 7-5.7 12.7-12.7 12.7zm0-23.1c-4.8 0-8.7 3.9-8.7 8.7v1.7c0 4.8 3.9 8.7 8.7 8.7s8.7-3.9 8.7-8.7v-1.7c0-4.8-3.9-8.7-8.7-8.7zM64.3 188.1c-1.1 0-2-.9-2-2v-68.3c0-1.1.9-2 2-2s2 .9 2 2v68.3c0 1.1-.9 2-2 2zM78.8 188.1c-1.1 0-2-.9-2-2v-38.2c0-1.1.9-2 2-2s2 .9 2 2v38.2c0 1.1-.9 2-2 2z"
				fill="#1C1C1B"
			/>
			<path
				d="M51.1 148.7c-1.1 0-2-.9-2-2v-27.6C49.1 105.8 60 95 73.3 95h19.5c1.1 0 2 .9 2 2s-.9 2-2 2H73.3c-11.1 0-20.2 9-20.2 20.2v27.6c0 1-.9 1.9-2 1.9zM93.4 188.1c-1.1 0-2-.9-2-2v-38.6c0-1.1.9-2 2-2s2 .9 2 2v38.6c0 1.1-.9 2-2 2z"
				fill="#1C1C1B"
			/>
		</symbol>
		<symbol id="statistics-icon-3">
			<path
				d="M80.1 183.5c-1.1 0-2-.9-2-2v-79.6c0-23 18.7-41.7 41.7-41.7 18.6 0 35.1 12.5 40.1 30.3.3 1.1-.3 2.2-1.4 2.5-1.1.3-2.2-.3-2.5-1.4-4.6-16.2-19.5-27.4-36.3-27.4-20.8 0-37.7 16.9-37.7 37.7v79.6c.1 1.1-.8 2-1.9 2z"
				fill="#1C1C1B"
			/>
			<path
				d="M130.2 183.5c-1.1 0-2-.9-2-2v-18.1c0-1.1.9-2 2-2h16.9c5.4 0 9.9-4.4 9.9-9.9V136c0-1.1.9-2 2-2h9c.6 0 1.1-.3 1.4-.7.3-.5.4-1 .2-1.6l-13.4-40c-.4-1 .2-2.2 1.3-2.5 1-.4 2.2.2 2.5 1.3l13.4 40c.6 1.7.3 3.7-.8 5.2s-2.8 2.4-4.7 2.4h-7v13.6c0 7.7-6.2 13.9-13.9 13.9h-14.9v16.1c.1.9-.8 1.8-1.9 1.8zM198.1 93.1c-.9 0-1.7-.6-1.9-1.5-4.6-16.2-19.5-27.4-36.3-27.4-3.7 0-7.4.5-10.9 1.6-1.1.3-2.2-.3-2.5-1.3-.3-1.1.3-2.2 1.3-2.5 3.9-1.2 7.9-1.8 12-1.8 18.6 0 35.1 12.5 40.1 30.3.3 1.1-.3 2.2-1.4 2.5-.1 0-.3.1-.4.1z"
				fill="#1C1C1B"
			/>
			<path
				d="M170.2 183.5c-1.1 0-2-.9-2-2v-18.1c0-1.1.9-2 2-2h16.9c5.4 0 9.9-4.4 9.9-9.9V136c0-1.1.9-2 2-2h9c.6 0 1.1-.3 1.4-.7.3-.5.4-1 .2-1.6l-13.4-40c-.4-1 .2-2.2 1.3-2.5 1-.4 2.2.2 2.5 1.3l13.4 40c.6 1.7.3 3.7-.8 5.2s-2.8 2.4-4.7 2.4h-7v13.6c0 7.7-6.2 13.9-13.9 13.9h-14.9v16.1c.1.9-.8 1.8-1.9 1.8z"
				fill="#1C1C1B"
			/>
			<path
				fill="#ff7a50"
				d="M128.3 139.1c-.7 0-1.3-.4-1.7-1l-4.2-7c-1.4.1-2.6.1-4 0l-4.2 7c-.5.8-1.4 1.1-2.3.9-2.1-.6-4.1-1.4-5.9-2.5-.8-.4-1.2-1.4-1-2.2l2-7.9c-1-.8-2-1.8-2.8-2.8l-7.9 2c-.9.2-1.8-.2-2.2-1-1-1.9-1.8-3.9-2.5-5.9-.3-.9.1-1.8.9-2.3l7-4.2c-.1-.7-.1-1.4-.1-2 0-.6 0-1.3.1-2l-7-4.2c-.8-.5-1.1-1.4-.9-2.3.6-2.1 1.4-4.1 2.5-5.9.4-.8 1.4-1.2 2.2-1l7.9 2c.8-1 1.8-2 2.8-2.8l-2-7.9c-.2-.9.2-1.8 1-2.2 1.9-1 3.9-1.8 5.9-2.5.9-.3 1.8.1 2.3.9l4.2 7c1.4-.1 2.6-.1 4 0l4.2-7c.5-.8 1.4-1.1 2.3-.9 2.1.6 4.1 1.4 5.9 2.5.8.4 1.2 1.4 1 2.2l-2 7.9c1 .8 2 1.8 2.8 2.8l7.9-2c.9-.2 1.8.2 2.2 1 1 1.9 1.8 3.9 2.5 5.9.3.9-.1 1.8-.9 2.3l-7 4.2c.1.7.1 1.4.1 2 0 .6 0 1.3-.1 2l7 4.2c.8.5 1.1 1.4.9 2.3-.6 2.1-1.4 4.1-2.5 5.9-.4.8-1.4 1.2-2.2 1l-7.9-2c-.8 1-1.8 2-2.8 2.8l2 7.9c.2.9-.2 1.8-1 2.2-1.9 1-3.9 1.8-5.9 2.5-.2 0-.4.1-.6.1zm-4.9-12.1c.7 0 1.4.4 1.7 1l4 6.7c.8-.3 1.6-.6 2.3-1l-1.9-7.6c-.2-.8.1-1.6.8-2.1 1.5-1.1 2.8-2.4 3.8-3.8.5-.7 1.3-1 2.1-.8l7.6 1.9c.4-.7.7-1.5 1-2.3l-6.7-4c-.7-.4-1.1-1.2-.9-2 .2-1 .2-1.9.2-2.7 0-.8-.1-1.7-.2-2.7-.1-.8.2-1.6.9-2l6.7-4c-.3-.8-.6-1.6-1-2.3l-7.6 1.9c-.8.2-1.6-.1-2.1-.8-1.1-1.5-2.4-2.8-3.8-3.8-.7-.5-1-1.3-.8-2.1l1.9-7.6c-.7-.4-1.5-.7-2.3-1l-4 6.7c-.4.7-1.2 1.1-2 .9-2-.3-3.4-.3-5.4 0-.8.1-1.6-.2-2-.9l-4-6.7c-.8.3-1.6.6-2.3 1l1.9 7.6c.2.8-.1 1.6-.8 2.1-1.5 1.1-2.8 2.4-3.8 3.8-.5.7-1.3 1-2.1.8L97 99.3c-.4.7-.7 1.5-1 2.3l6.7 4c.7.4 1.1 1.2.9 2-.2 1-.2 1.9-.2 2.7 0 .8.1 1.7.2 2.7.1.8-.2 1.6-.9 2l-6.7 4c.3.8.6 1.6 1 2.3l7.6-1.9c.8-.2 1.6.1 2.1.8 1.1 1.5 2.4 2.8 3.8 3.8.7.5 1 1.3.8 2.1l-1.9 7.6c.7.4 1.5.7 2.3 1l4-6.7c.4-.7 1.2-1.1 2-.9 2 .3 3.4.3 5.4 0 .1-.1.2-.1.3-.1zm-3-5c-6.5 0-11.8-5.3-11.8-11.8s5.3-11.8 11.8-11.8c6.5 0 11.7 5.3 11.7 11.8s-5.2 11.8-11.7 11.8zm0-19.5c-4.3 0-7.8 3.5-7.8 7.8s3.5 7.8 7.8 7.8 7.7-3.5 7.7-7.8-3.4-7.8-7.7-7.8z"
			/>
			<path
				d="M120.4 50.7c-1.1 0-2-.9-2-2V22.5c0-1.1.9-2 2-2s2 .9 2 2v26.3c0 1-.9 1.9-2 1.9zM67.3 112.2H41.7c-1.1 0-2-.9-2-2s.9-2 2-2h25.6c1.1 0 2 .9 2 2s-.9 2-2 2zM77.6 66.9c-.5 0-1-.2-1.4-.6L57 47.1c-.8-.8-.8-2 0-2.8.8-.8 2-.8 2.8 0L79 63.4c.8.8.8 2 0 2.8-.4.5-.9.7-1.4.7zM99 54.7c-.8 0-1.5-.5-1.8-1.2l-6.2-15c-.4-1 .1-2.2 1.1-2.6 1-.4 2.2.1 2.6 1.1l6.2 15c.4 1-.1 2.2-1.1 2.6-.3.1-.5.1-.8.1zM67.7 90.5c-.3 0-.5 0-.8-.2l-15-6.2c-1-.4-1.5-1.6-1.1-2.6.4-1 1.6-1.5 2.6-1.1l15 6.2c1 .4 1.5 1.6 1.1 2.6-.2.8-1 1.3-1.8 1.3z"
				fill="#1C1C1B"
			/>
		</symbol>
	</svg>
);

const FreeOfferAndStatisticsSectionComponent = ({ activeLanguage }) => (
	<div className="section free-offer-statistics-section" style={{ minHeight: 'auto' }} id="section-2">
		<div className="statistics">
			<SvgIcons />
			<div className="statistics__container">
				<h1 className="statistics__title">{activeLanguage.why_jcw_title}</h1>
				<p className="statistics__subtitle px16-pale-text">{activeLanguage.why_jcw_subtitle}</p>
				<div className="statistics__features row">
					<div className="statistics__feature statistics-block col-lg-8 col-md-8 col-sm-8 col-xs-24">
						<svg className="statistics__feature-icon" width="260" height="223" viewBox="0 0 260 223">
							<use xlinkHref="#statistics-icon-1" />
						</svg>
						<p className="statistics__feature-text type">{activeLanguage.reason_1}</p>
					</div>
					<div className="statistics__feature statistics-block col-lg-8 col-md-8 col-sm-8 col-xs-24">
						<svg className="statistics__feature-icon" width="260" height="223" viewBox="0 0 260 223">
							<use xlinkHref="#statistics-icon-2" />
						</svg>
						<p className="statistics__feature-text type">{activeLanguage.reason_2}</p>
					</div>
					<div className="statistics__feature statistics-block col-lg-8 col-md-8 col-sm-8 col-xs-24">
						<svg className="statistics__feature-icon" width="260" height="223" viewBox="0 0 260 223">
							<use xlinkHref="#statistics-icon-3" />
						</svg>
						<p className="statistics__feature-text type">{activeLanguage.reason_3}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

function mapStateToProps(state) {
	return {
		activeLanguage: state.language.languages[state.language.activeLanguage],
	};
}

export const FreeOfferAndStatisticsSection = connect(mapStateToProps)(FreeOfferAndStatisticsSectionComponent);
